import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwipeCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SliderItemProject from '../../Header/Swiper-project/SliderItemProject';
import './SliderContainerProjectCard.css';

const bucket_url = process.env.REACT_APP_S3_BUCKET_URL;

const SliderContainerProjectCard = ({ projects }) => {
  const [, setCurrentIndex] = useState(0);
  const swiperRef = useRef(null);

  SwipeCore.use([Navigation, Pagination, Autoplay]);

  const colors = {
    Архитектура: '#FF7F6A',
    BIM: '#75BBFD',
    Конструкции: '#566272',
    Дизайн: '#FAA8BD',
    Инженерия: '#90B734',
  };

  // const handlePageClick = (index) => {
  //   setCurrentIndex(index);
  //   if (swiperRef.current && swiperRef.current.slideTo) {
  //     swiperRef.current.slideTo(index);
  //   }
  // };

  const isFullUrl = (url) => url.startsWith('http://') || url.startsWith('https://');

const imageSrc = projects.imageProject
  ? isFullUrl(projects.imageProject)
    ? projects.imageProject
    : `${bucket_url}/images/imageAdd/${projects.imageProject}`
  : projects.imageTitle
    ? isFullUrl(projects.imageTitle)
      ? projects.imageTitle
      : `${bucket_url}/images/imageAdd/${projects.imageTitle}`
    : "";


  return (
    <div className="swiper_container_ProjectCard">
      <Swiper
        loop={false}
        slidesPerView={1.1}
        spaceBetween={-2}
        centeredSlides={true}
        touch="true"
        className="swiper_container_project_ProjectCard"
        onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)}
        onSwiper={(swiper) => {
          if (swiperRef.current === null) {
            swiperRef.current = swiper;
          }
        }}>
        <div className="swiper_wrapper_ProjectCard">
          {projects?.map((project) => {
            if (project) {
              return (
                <SwiperSlide className="swiper_container_item" key={project.id}>
                  <Link key={project.id} to={`/projects/${project.id}`}>
                    <SliderItemProject
                      className="swiper_item_image"
                      title={project.title}
                      src={imageSrc}
                      text={project.text}
                      id={project.link}
                      selectCompetencies={(() => {
                        try {
                          const selectCompetencies = project.selectCompetencies;

                          if (Array.isArray(selectCompetencies)) {
                            // ... (то же, что и в предыдущем коде)
                          } else if (typeof project.selectCompetencies === 'string') {
                            // Если строка с одним значением и без запятых, отобразить компетенцию
                            if (!project.selectCompetencies.includes(',')) {
                              const label = project.selectCompetencies.trim();
                              return (
                                <div
                                  key={label}
                                  className="div_el_selectCompetencies_ProjectCard"
                                  style={{
                                    color: colors[label],
                                    borderColor: colors[label],
                                    border: '0.5px solid',
                                    padding: '3px 6px',
                                    borderRadius: '34px',
                                  }}>
                                  {label}
                                </div>
                              );
                            } else {
                              // Строка с несколькими значениями, разделенными запятыми
                              return project.selectCompetencies.split(',').map((value) => {
                                const label = value.trim();
                                return (
                                  <div
                                    key={value}
                                    className="div_el_selectCompetencies_ProjectCard"
                                    style={{
                                      color: colors[value],
                                      borderColor: colors[value],
                                      border: '0.5px solid',
                                      padding: '3px 6px',
                                      borderRadius: '34px',
                                    }}>
                                    {label}
                                  </div>
                                );
                              });
                            }
                          }
                        } catch (error) {
                          return null;
                        }
                      })()}
                      countryCity={project.countryCity}
                      monthYear={project.monthYear}
                    />
                  </Link>
                </SwiperSlide>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Swiper>
      {/* <div className="swiper_pagination_ProjectCard">
        {projects && projects?.map((_, index) => (
            <button
              key={index}
              className={`swiper_pagination_bullet_ProjectCard ${
                currentIndex === index ? "active" : ""
              }`}
              onClick={() => handlePageClick(index)}
            />
          ))}
      </div> */}
    </div>
  );
};

export default SliderContainerProjectCard;
